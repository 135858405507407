



















































































































































































































import Vue from "vue";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import {
  ApiException,
  UserBlockAdminRequest,
  UserFullProfileAdminResponse,
  UsersAdminClient,
  UserVerifyAdminRequest,
} from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";
import AppPage from "@/components/layout/AppPage.vue";
import AppWebinarsTable from "@/components/tables/AppWebinarsTable.vue";

export default Vue.extend({
  name: "UserProfileView",
  components: { AppWebinarsTable, AppPage, AppPageBanner, AppPageLoading },
  props: {
    id: String,
  },
  data: () => ({
    loading: true,
    errorMessage: "",
    user: null as UserFullProfileAdminResponse | null,
    tab: null,
    deleteWarning: false,
  }),
  computed: {
    userFullName(): string {
      if (!this.user) return "";
      return [this.user.title, this.user.firstName, this.user.lastName]
        .filter((p) => p?.length)
        .join(" ");
    },
  },
  async created() {
    try {
      const client = await this.getClient();
      this.user = await client.getUserProfile(this.id);
    } catch (e) {
      this.errorMessage = (e as ApiException).message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getClient(): Promise<UsersAdminClient> {
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      return this.$httpClientFactory(UsersAdminClient, token.accessToken);
    },
    async updateVerification(): Promise<void> {
      this.loading = true;
      try {
        const client = await this.getClient();
        const request = new UserVerifyAdminRequest();
        request.verify = !this.user?.verified;
        const response = await client.verifyUser(this.id, request);
        this.user = Object.assign({}, this.user, response);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    async updateBlock(): Promise<void> {
      this.loading = true;
      try {
        const client = await this.getClient();
        const request = new UserBlockAdminRequest();
        request.block = !this.user?.blocked;
        const response = await client.blockUser(this.id, request);
        this.user = Object.assign({}, this.user, response);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    async deleteUser(): Promise<void> {
      this.loading = true;
      try {
        const client = await this.getClient();
        await client.deleteUser(this.id);
        await this.$router.push({ name: "UsersDashboardView" });
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
